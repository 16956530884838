<template>
  <div class="col-md-7">
    <div
      class="card"
      style="border: none"
      id="print"
    >
      <div class="card-body">
        <div class="text-center">
          <img
            src="/images/hayandra.jpeg"
            height="70"
          >
          <span class="d-block">{{address.address}}</span> 
          <span class="d-block mb-3">{{address.phone}}</span> 
          <h4 style="font-weight: bold; margin-top: 20px; text-transform: uppercase;">
            Slip Gaji Karyawan
            <hr style="border-bottom: 2px solid green; margin-right: 200px; margin-left: 200px; margin-top: 5px; margin-bottom: 5px;" />
          </h4>
        </div>
        <div class="row mb-2">
            <div class="col-12">
                <table class="font-weight-bolder">
                  <tr>
                    <td style="width: 120px; padding: 5px">Nama/NIP</td>
                    <td>: <span class="font-weight-bolder">{{ data.employee_name +" ("+ data.employee_number_id+")" }}</span></td>
                  </tr>
                  <tr>
                    <td style="width: 120px; padding: 5px">Bulan Gaji</td>
                    <td>: <span class="font-weight-bolder">{{ data.display_paid_month }}</span></td>
                  </tr>
                  <tr>
                    <td style="width: 120px; padding: 5px">Jabatan</td>
                    <td>: <span class="font-weight-bolder">{{ employee.position }}</span></td>
                  </tr>

                </table>
            </div>
        </div>
        <div
          class="row justify-content-center"
          style="font-size: 12px;"
        >
        <div class="col-md-12">

        <table class="table table-vertical-center">
            <thead>
              <tr>
                <th class="p-0" style="width: 100px"></th>
                <th class="p-0" style="min-width: 100px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="tableDisplay.paymentDisplay == true">
                <td style="width:60%">
                  <span class="font-weight-bolder" >Pembayaran</span>
                </td>
                <td>
                  <span class="font-weight-bolder" >Jumlah</span>
                </td>
              </tr>

              <!-- pembayaran -->
              <template v-for="(item, i) in paymentRemuneration">
                <tr v-bind:key="i">
                  <td class="pl-0">
                      <span class="ml-5 mr-2">{{item.remuneration_type_name}}</span> 
                      <span v-if="item.notes != ''" >{{" ("+item.notes+")"}}</span> 
                      {{item.qty > 1 ? " ("+ item.qty+" x "+ parseInt(item.ammount).toLocaleString('id-ID')+")" :""}}
                      <!-- it's for remuneration payment name  -->
                  </td>
                  <td>
                    <span
                      class="font-align-bold"
                    >
                      <!-- {{"Rp "+parseInt(item.ammount).toLocaleString('id-ID')}} -->
                      {{"Rp "+parseInt(item.ammount*item.qty).toLocaleString('id-ID')}}

                      <!-- it's for amount -->
                    </span>
                  </td>
                </tr>
              </template>

              <!-- sub total -->
              <tr class="font-weight-bolder ">
                <td colspan="2" class="text-right"><span class="font-align-bold">{{"Rp +"+parseInt(subTotalPayment).toLocaleString('id-ID')}}</span></td>
                <!-- its for sub total -->
              </tr>

              <!-- potongan -->
              <tr v-if="tableDisplay.pieceDisplay == true">
                <td colspan="2" >
                  <span class="font-weight-bolder">Potongan</span>
                </td>
              </tr>
              <template v-for="(data, j) in pieceRemuneration">
                <tr v-bind:key="j">
                  <td class="pl-0">
                      <span class="ml-5 mr-2">{{data.remuneration_type_name}}</span> 
                      <span v-if="data.notes != ''" >{{" ("+data.notes+")"}}</span> 
                      {{data.qty > 1 ? " ("+ data.qty+" x "+ parseInt(data.ammount).toLocaleString('id-ID')+")" :""}}
                      <!-- it's for remuneration payment name  -->
                  </td>
                  <td>
                    <span
                      class="font-align-bold"
                    >
                      {{"Rp -"+parseInt(data.ammount*data.qty).toLocaleString('id-ID')}}

                      <!-- it's for amount -->
                    </span>
                  </td>
                </tr>
              </template>

              <!-- sub total -->
              <tr class="font-weight-bolder " v-if="subTotalPiece > 0">
                <td colspan="2" class="text-right"><span class="font-align-bold">{{"Rp -"+parseInt(subTotalPiece).toLocaleString('id-ID')}}</span></td>
                <!-- its for sub total -->
              </tr>
        
              <!-- pajak -->
              <tr v-if="tableDisplay.taxDisplay == true">
                <td colspan="2">
                  <span class="font-weight-bolder">Pajak</span>
                </td>
              </tr>
              <template v-for="(tax, k) in taxRemuneration">
                <tr v-bind:key="k">
                  <td class="pl-0">
                      <span class="ml-5 mr-2">{{tax.remuneration_type_name}}</span>
                      <span v-if="tax.notes != ''" >{{" ("+tax.notes+")"}}</span> 
                      {{tax.qty > 1 ? " ("+ tax.qty+" x "+ parseInt(tax.ammount).toLocaleString('id-ID')+")" :""}} 
                      <!-- it's for remuneration payment name  -->
                  </td>
                  <td>
                    <span
                      class="font-align-bold"
                    >
                      {{parseInt(tax.ammount*tax.qty)+"%"}}

                      <!-- it's for amount -->
                    </span>
                  </td>
                </tr>
              </template>

               <!-- sub total -->
              <tr class="font-weight-bolder " v-if="subTotalTax > 0">
                <td colspan="2" class="text-right"><span class="font-align-bold">{{"Rp -"+parseInt(subTotalTax).toLocaleString('id-ID')}}</span></td>
                <!-- its for sub total -->
              </tr>
              
              <!-- total -->
              <tr class="font-weight-bolder ">
                <td><span class="font-weight-bolder">Total Pendapatan</span></td>
                <td class="text-right"><span class="font-align-bold">{{"Rp "+parseInt(data.ammount).toLocaleString('id-ID')}}</span></td>
                <!-- its for sub total -->
              </tr>
            </tbody>
          </table>

        </div>
        </div>

        <div class="row">
          <div class="col-md-6 text-center">
            <span>Jakarta,{{data.display_paid_date_month}}</span>
          </div>
        </div>
        <div class="row" style="margin-top:100px">
          <div class="col-md-6 text-center">
            <span class="font-weight-bolder d-block">DR.dr.Karina,Sp.BP-RE</span>
            <span>(Kepala Klinik IONA)</span>
          </div>
          <div class="col-md-6 text-center">
            <span>Penyusun : {{data.created_by_name}}</span>
          </div>
        </div>
    </div>
  </div>
  </div>
</template>

<script>
import module from '@/core/modules/CrudModule.js'
export default {

  props: {
    data: Object,
    paymentRemuneration:Array,
    pieceRemuneration:Array,
    taxRemuneration:Array,
    tableDisplay:Object,
    employee:Object,
    subTotalPayment:Number,
    subTotalPiece:Number,
    subTotalTax:Number,
    address:Object,

  },



  


}
</script>

<style>
</style>